<template>
  <b-modal
    id="edit-motivation"
    :title="mTitle"
    :ok-disabled="true"
    :cancel-disabled="true"
    @show="onShow"
    @hide="onHide"
  >
    <template v-if="!loading">
      <select-dropdown :is-disabled="loading" :items="years" v-model="year"></select-dropdown>
      <table>
        <thead>
          <tr>
            <th class="_name">&nbsp;</th>
            <th v-for="(month, i) in months" :id="i">{{ getMonthName(month) }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(setting, key) in settings">
            <td class="_name">
              {{ setting }}
              <a-tooltip
                :overlayStyle="{ textAlign: 'center', maxWidth: '300px' }"
                v-if="iconMotivation && iconMotivation.key === key"
              >
                <template v-if="iconMotivation.text" slot="title">
                  {{ iconMotivation.text }}
                </template>
                <a-icon style="margin-left: 8px" type="plus-circle" theme="twoTone" @click="handleCompletion" />
              </a-tooltip>
            </td>
            <td v-for="(month, i) in months" :id="i">
              <input
                type="number"
                v-model="motivation[`01${month}${year}`] && motivation[`01${month}${year}`][key]"
                @input="e => handleInput(e, { month, year, key, i })"
                @focus="handleFocus"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="submit">
        <button type="submit" @click.prevent="saveMotivation" class="btn btn-primary" :disabled="loading">
          {{ loading ? 'Выполняется...' : mOkTitle }}
        </button>
        <button
          class="btn btn-default"
          :disabled="loading"
          @click.prevent="$root.$emit('bv::hide::modal', 'edit-motivation')"
        >
          Отмена
        </button>
      </div>
      <div class="motivation-history">
        <h5>История изменений мотивации:</h5>
        <ul v-if="(history || []).length">
          <li v-for="i in history">
            {{ formatDate(i.createdAt) }} - {{ i.user.name }}
            <button
              class="btn btn-primary btn-xs"
              style="padding-left: 0.7rem; padding-right: 0.7rem; position: relative; top: -2px; margin-left: 5px"
              :disabled="loading"
              @click.prevent="restoreMotivation(i.id, i.extra)"
            >
              восстановить эту версию
            </button>
          </li>
        </ul>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import api from '../../assets/js/api'

  import range from 'lodash/range'
  import map from 'lodash/map'
  import each from 'lodash/each'
  import SelectDropdown from '../../components/SelectDropdown'
  import moment from 'moment'
  import { clone } from 'lodash'

  export default {
    name: 'edit-motivation',
    components: { SelectDropdown },
    props: ['module'],
    data() {
      return {
        year: moment().format('YYYY'),
        months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        settings: {
          rate_per_hour: 'На руки в час',
          bonus_per_hour: 'Премия в час',
          managers_bonus_of_project: 'Бонус менеджера (проект), %',
          managers_bonus_of_acts: 'Бонус менеджера (акты), %',
          first_cost_per_hour: 'Себестоимость в час',
          cost_per_hour: 'Стоимость для клиента',
        },
        form: {
          user: {},
        },
        rawMotivation: {},
        motivation: {},
        history: [],
        loading: true,

        cacheMotivation: {},
        completion: {},
        iconMotivation: { key: null, text: null },
      }
    },
    computed: {
      user() {
        return (this.form && this.form.user) || {}
      },
      fullName() {
        return `${this.user.first_name} ${this.user.surname}`
      },
      years() {
        return map(range(2015, 2025), y => {
          return {
            name: y.toString(),
            value: y,
          }
        })
      },
      mTitle() {
        if (!this.form) {
          return ''
        }

        return `Настройка мотивации сотрудника - ${this.fullName}`
      },
      mOkTitle() {
        return 'Сохранить мотивацию'
      },
    },
    watch: {
      year() {
        this.checkMotivations()
      },
    },
    methods: {
      handleInput(e, m) {
        const { month, year, key, i } = m
        const text = []
        for (let i = +this.completion.month; i < 13; i++) {
          text.push(this.getMonthName(i))
        }
        this.iconMotivation = {
          key,
          text: `Заполнить ${text.length > 1 ? text[0] + ' - ' + text[text.length - 1] : text[0]} \n значением: ${
            e.target.value
          }`,
        }
        this.cacheMotivation = clone(this.motivation)
        this.completion = { month, year, key, i, value: e.target.value }
      },

      handleFocus() {
        this.iconMotivation = ''
      },

      handleCompletion() {
        for (let i = +this.completion.month; i < 13; i++) {
          const date = `01${i < 10 ? '0' + i : i}${this.completion.year}`
          this.motivation[date][this.completion.key] = this.completion.value
        }
        this.iconMotivation = ''
      },
      restoreMotivation(id, extra) {
        this.motivation = extra

        // api.http.put(
        //   `/motivations/set-by-employee/${this.form.user.id}`,
        //   {historyId: id}
        // ).then(response => {
        //   this.$root.$emit('bv::hide::modal', 'edit-motivation')
        // }, err => {
        //   alert(err);
        // })
      },
      formatDate(date) {
        return moment(date).format('DD.MM.YYYY, HH:mm')
      },
      checkMotivations() {
        each(this.months, month => {
          let date = `01${month}${this.year}`

          try {
            if (!this.motivation[date]) {
              this.$set(this.motivation, date, {})
            }

            each(this.settings, (val, key) => {
              if (!this.motivation[date][key]) {
                this.$set(this.motivation[date], key, '')
              }
            })
          } catch (e) {}
        })
      },
      getMonthName(month) {
        return moment(month + '-2018', 'MM-YYYY').format('MMM')
      },
      saveMotivation() {
        Object.keys(this.motivation).forEach(period => {
          Object.keys(this.motivation[period]).forEach(settingName => {
            const value = this.motivation[period][settingName]
            if (value && !Number.isNaN(value)) {
              this.motivation[period][settingName] = Number(value)
            }
          })
        })

        api.http.put(`/motivations/set-by-employee/${this.form.user.id}`, { motivation: this.motivation }).then(
          () => {
            this.$root.$emit('bv::hide::modal', 'edit-motivation')
          },
          err => {
            alert(err)
          }
        )
      },
      onShow() {
        this.$emit('show', this)
      },
      onHide() {
        this.$emit('hide', this)
      },
    },
  }
</script>

<style lang="scss">
  #edit-motivation .modal-dialog {
    max-width: 840px !important;
  }
  #edit-motivation .modal-footer {
    display: none !important; /* я не знаю как выключить кнопки и возможно ли это*/
  }
</style>

<style lang="scss" scoped>
  ::v-deep .ant-table {
    font-size: 12.35px;
    white-space: nowrap;

    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: transparent;
    }
    .ant-table-body > table > .ant-table-tbody > tr > td {
      padding: 0 10px;
    }
    .ant-input:focus {
      border-color: transparent;
      box-shadow: none;
    }
    .ant-table-body {
      margin: 0;
    }
    .ant-table-thead {
      font-weight: normal;
    }
  }

  .motivation-history {
    h5 {
      margin-top: 2rem;
    }
    ul {
      li {
        margin-top: 0.5rem;
      }
    }
  }
  .b-dropdown {
    width: 150px !important;
  }
  table {
    table-layout: fixed;
    border: 1px solid #ddd;
    margin: 2rem 0 0 0;

    th,
    td {
      $col-width: 47.5px;

      border: 1px solid #ddd;
      text-align: center;
      width: $col-width;
      min-width: $col-width;
      max-width: $col-width;

      &._name {
        width: auto;
        min-width: 225px;
        padding: 5px 10px;
        text-align: left;
      }
    }

    input {
      width: 100%;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      border: 0;
      text-align: center;
    }
  }
  .submit {
    padding: 2rem 0 0 0;

    .btn {
      margin: 0 1rem 0 0;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
</style>
